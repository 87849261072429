export const excelStyles = [
    {
        id: 'header',
        interior: {
            color: '#212b4b',
            pattern: 'Solid'
        },
        alignment: {
            horizontal: 'Center', vertical: 'Center'
        },
        font: {
            fontName: "Arial",
            size: 11,
            color: '#FFFFFF',
            bold: true
        },
        borders: {
            borderBottom: {
                color: '#000000',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#000000',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#000000',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderTop: {
                color: '#000000',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
    },
    {
        id: 'body',
        font: {
            fontName: "Arial",
            size: 10
        },
        borders: {
            borderBottom: {
                color: '#000000',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#000000',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#000000',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderTop: {
                color: '#000000',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
    },
];