import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class PortfolioService extends BaseService {

	modelName = 'portfolio';

	constructor(
		private _http: HttpClient,
		private _portfolioService: PortfolioService,
	) {
		super(_http);

		this.model = (construct: any) => {
			return Object.assign({}, construct);
		};
	}
}
